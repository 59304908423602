<template>
    <div class="container mt-3 mb-3">
        <h1 class="fw-bold mb-3">{{ subCategoryName }}</h1>
        <h3 class="fw-bold mb-3">Options</h3>
        <ul
            class="list-unstyled ms-2 px-4"
            v-if="subCategories && subCategories.length > 0"
        >
            <li
                class="border-bottom py-3"
                v-for="(cat, index) in subCategories"
                :key="index"
                @click="toListPage(cat.name, cat.id)"
            >
                {{ cat.name }}
            </li>
        </ul>
        <ul class="list-unstyled ms-2 px-4" v-else>
            <li class="border-bottom py-3">
                No sub categories found!
            </li>
        </ul>
    </div>
</template>

<script>
import SubCategories from '@/graphql/queries/product/SubCategories.gql';
import { mapGetters } from 'vuex';

export default {
    computed: {
        subCategoryName() {
            return this.$route.query.name;
        },

        ...mapGetters({
            subCategories: 'product/getSubCategories',
        }),
    },

    mounted() {
        this.getSubCategories();
    },

    methods: {
        getSubCategories() {
            if (this.$route.params.id) {
                this.$apollo
                    .query({
                        query: SubCategories,

                        variables: {
                            category_id: this.$route.params.id,
                        },
                    })
                    .then(response => {
                        this.$store.commit(
                            'product/ADD_SUB_CATEGORIES',
                            response.data.subCategories,
                        );
                    })
                    .catch(error => {
                        console.log(error.response);
                    });
            }
        },

        toListPage(name, id) {
            this.$router.push({
                name: 'ProductList',
                query: {
                    filter_tool: name,
                    type: 'category',
                    id: id,
                    category_id: id,
                },
            });
        },
    },
};
</script>
